	/*
  	Flaticon icon font: Flaticon
  	Creation date: 05/02/2020 06:06
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 0px;
}

.flaticon-play-button:before { content: "\f100"; }
.flaticon-play-button-1:before { content: "\f101"; }
.flaticon-back:before { content: "\f102"; }
.flaticon-next:before { content: "\f103"; }
.flaticon-right-quote:before { content: "\f104"; }
.flaticon-left-quote:before { content: "\f105"; }
.flaticon-triangle:before { content: "\f106"; }
.flaticon-play-button-2:before { content: "\f107"; }
.flaticon-quote:before { content: "\f108"; }
.flaticon-paper-plane:before { content: "\f109"; }
.flaticon-envelope:before { content: "\f10a"; }
.flaticon-placeholder:before { content: "\f10b"; }
.flaticon-diamond:before { content: "\f10c"; }
.flaticon-diamond-1:before { content: "\f10d"; }
.flaticon-aim:before { content: "\f10e"; }
.flaticon-target:before { content: "\f10f"; }
.flaticon-darts:before { content: "\f110"; }
.flaticon-aim-1:before { content: "\f111"; }
.flaticon-message:before { content: "\f112"; }
.flaticon-mail:before { content: "\f113"; }
.flaticon-guarantee:before { content: "\f114"; }
.flaticon-start:before { content: "\f115"; }
.flaticon-rocket:before { content: "\f116"; }
.flaticon-content:before { content: "\f117"; }
.flaticon-email:before { content: "\f118"; }
.flaticon-digital-campaign:before { content: "\f119"; }
.flaticon-promotion:before { content: "\f11a"; }
.flaticon-facebook:before { content: "\f11b"; }
.flaticon-pay-per-click:before { content: "\f11c"; }
.flaticon-link:before { content: "\f11d"; }
.flaticon-chain:before { content: "\f11e"; }
.flaticon-work:before { content: "\f11f"; }
.flaticon-speaker:before { content: "\f120"; }
.flaticon-speaker-1:before { content: "\f121"; }
.flaticon-coffee-cup:before { content: "\f122"; }
.flaticon-search:before { content: "\f123"; }
.flaticon-shopping-basket:before { content: "\f124"; }
.flaticon-strategy:before { content: "\f125"; }
.flaticon-replay-arrow:before { content: "\f126"; }
.flaticon-house:before { content: "\f127"; }
.flaticon-call:before { content: "\f128"; }
.flaticon-email-1:before { content: "\f129"; }
.flaticon-alarm:before { content: "\f12a"; }
.flaticon-visibility:before { content: "\f12b"; }
.flaticon-view:before { content: "\f12c"; }