.blog-single-section {
    /*** tag-share ***/
    /*** author-box ***/
    /*** more-posts ***/
    /*** comments area ***/
    /*** comment-respond ***/
  }
  
  .blog-single-section .date-entry-meta {
    position: relative;
    padding-top: 30px;
    margin-bottom: 35px;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .date-entry-meta {
      margin-bottom: 25px;
    }
  }
  
  .blog-single-section .date-entry-meta .entry-meta {
    border-top: 0;
    padding: 25px 0 0;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .date-entry-meta .entry-meta {
      padding-top: 10px;
    }
  }
  
  .blog-single-section .date-entry-meta .entry-meta span {
    font-size: 14px;
    font-size: 0.93333rem;
    font-weight: 600;
    color: #687693;
    display: inline-block;
  }
  
  .blog-single-section .date-entry-meta .entry-meta > span + span {
    margin-left: 50px;
  }
  
  .blog-single-section .date-entry-meta .entry-meta span a {
    color: #687693;
  }
  
  .blog-single-section .date-entry-meta .entry-meta span a:hover {
    color: #fc6e36;
  }
  
  .blog-single-section .date-entry-meta .entry-meta > span + span {
    position: relative;
  }
  
  .blog-single-section .date-entry-meta .entry-meta > span + span:before {
    content: "";
    background-color: #687693;
    width: 22px;
    height: 2px;
    position: absolute;
    left: -36px;
    top: 9px;
  }
  
  .blog-single-section .post h2 {
    font-size: 30px;
    font-size: 2rem;
    margin: -0.22em 0 0.7em;
    line-height: 1.3em;
  }
  
  @media (max-width: 991px) {
    .blog-single-section .post h2 {
      font-size: 30px;
      font-size: 2rem;
    }
  }
  
  @media (max-width: 767px) {
    .blog-single-section .post h2 {
      font-size: 25px;
      font-size: 1.66667rem;
    }
  }
  
  .blog-single-section .post p {
    margin-bottom: 1.5em;
  }
  
  .blog-single-section .post h3 {
    font-size: 24px;
    font-size: 1.6rem;
    line-height: 1.3em;
    margin: 1.8em 0 1em;
  }
  
  @media (max-width: 991px) {
    .blog-single-section .post h3 {
      font-size: 22px;
      font-size: 1.46667rem;
    }
  }
  
  @media (max-width: 767px) {
    .blog-single-section .post h3 {
      font-size: 20px;
      font-size: 1.33333rem;
    }
  }
  
  .blog-single-section .post blockquote {
    background-color: #f0f1f4;
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #233d62;
    line-height: 1.6em;
    padding: 35px 25px 35px 100px;
    margin-top: 60px;
    border: 0;
    position: relative;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .post blockquote {
      padding: 25px;
    }
  }
  
  .blog-single-section .post blockquote:before {
    font-family: "Flaticon";
    content: "\f105";
    font-size: 45px;
    font-size: 3rem;
    color: #fc6e36;
    position: absolute;
    left: 30px;
    top: 45px;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .post blockquote:before {
      display: none;
    }
  }
  
  .blog-single-section .post blockquote .quoter {
    font-size: 14px;
    font-size: 0.93333rem;
    display: block;
    margin-top: 15px;
  }
  
  .blog-single-section .tag-share {
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    margin: 75px 0 0;
    padding: 30px 0;
    color: #233d62;
  }
  
  .blog-single-section .tag-share ul {
    list-style: none;
    display: inline-block;
    overflow: hidden;
  }
  
  .blog-single-section .tag-share ul li {
    float: left;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .tag-share ul li {
      margin: 2px;
    }
  }
  
  .blog-single-section .tag-share ul > li + li {
    margin-left: 10px;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .tag-share ul > li + li {
      margin: 2px;
    }
  }
  
  .blog-single-section .tag-share .tag {
    float: left;
  }
  
  @media (max-width: 600px) {
    .blog-single-section .tag-share .tag {
      float: none;
    }
  }
  
  .blog-single-section .tag-share .tag > span {
    color: #233d62;
    font-weight: bold;
    display: inline-block;
    padding-right: 8px;
  }
  
  .blog-single-section .tag-share .tag ul {
    list-style: none;
    position: relative;
    top: 5px;
  }
  
  .blog-single-section .tag-share .tag li {
    position: relative;
  }
  
  .blog-single-section .tag-share .tag ul > li + li {
    margin-left: 10px;
  }
  
  .blog-single-section .tag-share .tag a {
    background-color: #f5f5f5;
    font-size: 13px;
    font-size: 0.86667rem;
    color: #687693;
    padding: 6px 15px;
    border-radius: 0;
    display: inline-block;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .tag-share .tag a {
      font-size: 13px;
      font-size: 0.86667rem;
    }
  }
  
  .blog-single-section .tag-share .tag a:hover {
    background-color: #fc6e36;
    color: #fff;
  }
  
  .blog-single-section .tag-share .share {
    float: right;
    position: relative;
    top: -15px;
  }
  
  @media (max-width: 600px) {
    .blog-single-section .tag-share .share {
      float: none;
      margin-top: 15px;
    }
  }
  
  .blog-single-section .tag-share .share > span {
    color: #233d62;
    font-weight: 600;
    display: inline-block;
    padding-right: 8px;
  }
  
  .blog-single-section .tag-share .share ul {
    position: relative;
    top: 15px;
  }
  
  .blog-single-section .tag-share .share ul > li + li {
    margin-left: 10px;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .tag-share .share ul > li + li {
      margin-left: 8px;
    }
  }
  
  @media (max-width: 767px) {
    .blog-single-section .tag-share .share ul > li {
      margin: 5px;
    }
  }
  
  .blog-single-section .tag-share .share a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border: 1px solid #eae6e6;
    border-radius: 0;
    display: inline-block;
    font-size: 14px;
    font-size: 0.93333rem;
    color: #627381;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .tag-share .share a {
      font-size: 13px;
      font-size: 0.86667rem;
    }
  }
  
  .blog-single-section .tag-share .share a:hover {
    background: #fc6e36;
    border-color: #fc6e36;
    color: #fff !important;
  }
  
  .blog-single-section .tag-share .share ul > li:first-child a {
    color: #3c5ba4;
  }
  
  .blog-single-section .tag-share .share ul > li:nth-child(2) a {
    color: #47a0d9;
  }
  
  .blog-single-section .tag-share .share ul > li:nth-child(3) a {
    color: #0073b1;
  }
  
  .blog-single-section .tag-share .share ul > li:nth-child(4) a {
    color: #933f94;
  }
  
  .blog-single-section .author-box {
    padding: 35px 40px 25px;
    margin: 70px 0;
    -webkit-box-shadow: 0px 9px 57px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 9px 57px 0px rgba(0, 0, 0, 0.11);
  }
  
  @media (max-width: 991px) {
    .blog-single-section .author-box {
      padding: 35px 40px;
    }
  }
  
  @media (max-width: 767px) {
    .blog-single-section .author-box {
      padding: 25px;
    }
  }
  
  .blog-single-section .author-box .author-avatar {
    float: left;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .author-box .author-avatar {
      float: none;
    }
  }
  
  .blog-single-section .author-box .author-avatar img {
    border-radius: 5px;
  }
  
  .blog-single-section .author-box .author-content {
    display: block;
    overflow: hidden;
    padding-left: 25px;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .author-box .author-content {
      padding: 0;
      margin: 15px 0 0 0;
    }
  }
  
  .blog-single-section .author-box .author-content p {
    margin-bottom: 20px;
  }
  
  .blog-single-section .author-box .author-name {
    font-size: 16px;
    font-size: 1.06667rem;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
    color: #233d62;
  }
  
  .blog-single-section .author-box .social-link {
    display: inline-block;
    list-style: none;
  }
  
  .blog-single-section .author-box .social-link li {
    float: left;
    margin-right: 12px;
  }
  
  .blog-single-section .author-box .social-link a {
    display: block;
    font-size: 13px;
    font-size: 0.86667rem;
    color: #233d62;
  }
  
  .blog-single-section .author-box .social-link a:hover {
    color: #fc6e36;
  }
  
  .blog-single-section .more-posts {
    overflow: hidden;
  }
  
  .blog-single-section .more-posts > div {
    width: 50%;
    float: left;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .more-posts > div {
      width: 100%;
      float: none;
    }
  }
  
  .blog-single-section .more-posts > div > a {
    display: inline-block;
  }
  
  .blog-single-section .more-posts .next-post {
    text-align: right;
    padding-left: 15px;
    padding-right: 5px;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .more-posts .next-post {
      border-left: 0;
      text-align: left;
      border-top: 1px solid #dae9f9;
      padding-left: 5px;
      margin-top: 25px;
      padding-top: 25px;
    }
  }
  
  .blog-single-section .more-posts .next-post .post-control-link {
    padding-right: 25px;
    position: relative;
    text-transform: uppercase;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .more-posts .next-post .post-control-link {
      padding-right: 0;
    }
  }
  
  .blog-single-section .more-posts .next-post .post-control-link:before {
    font-family: "Flaticon";
    content: "\f103";
    position: absolute;
    right: 0;
    top: 0;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .more-posts .next-post .post-control-link:before {
      display: none;
    }
  }
  
  .blog-single-section .more-posts .previous-post {
    padding-right: 15px;
    padding-left: 5px;
  }
  
  .blog-single-section .more-posts .previous-post .post-control-link {
    padding-left: 25px;
    position: relative;
    text-transform: uppercase;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .more-posts .previous-post .post-control-link {
      padding-left: 0;
    }
  }
  
  .blog-single-section .more-posts .previous-post .post-control-link:before {
    font-family: "Flaticon";
    content: "\f103";
    position: absolute;
    left: 0;
    top: 0;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .more-posts .previous-post .post-control-link:before {
      display: none;
    }
  }
  
  .blog-single-section .more-posts .previous-post > a > span,
  .blog-single-section .more-posts .next-post > a > span {
    display: block;
  }
  
  .blog-single-section .more-posts .post-control-link {
    font-size: 14px;
    font-size: 0.93333rem;
    color: #687693;
  }
  
  .blog-single-section .more-posts .post-name {
    font-size: 17px;
    font-size: 1.13333rem;
    color: #233d62;
    margin: 0.7em 0 0;
    font-weight: 600;
  }
  
  @media (max-width: 991px) {
    .blog-single-section .more-posts .post-name {
      font-size: 18px;
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 7671px) {
    .blog-single-section .more-posts .post-name {
      margin: 0;
    }
  }
  
  .blog-single-section .more-posts a:hover .post-control-link {
    color: #fc6e36;
  }
  
  .blog-single-section .comments-area {
    margin-top: 70px;
  }
  
  .blog-single-section .comments-area li > div {
    border-bottom: 1px solid #f4f4f4;
    padding: 35px 0 35px 0;
  }
  
  @media (max-width: 991px) {
    .blog-single-section .comments-area li > div {
      padding: 35px 25px;
    }
  }
  
  .blog-single-section .comments-area ol {
    list-style-type: none;
    padding-left: 0;
  }
  
  .blog-single-section .comments-area ol ul {
    padding-left: 30px;
    list-style-type: none;
  }
  
  .blog-single-section .comments-area ol > li:last-child div {
    border-bottom: 0;
  }
  
  .blog-single-section .comments-area .comments-title {
    font-size: 22px;
    font-size: 1.46667rem;
    font-weight: 600;
    margin: 0;
  }
  
  @media (max-width: 991px) {
    .blog-single-section .comments-area .comments-title {
      font-size: 20px;
      font-size: 1.33333rem;
    }
  }
  
  .blog-single-section .comments-area li > div {
    position: relative;
  }
  
  .blog-single-section .comments-area .comment-theme {
    position: absolute;
    left: 0;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .comments-area .comment-theme {
      position: static;
    }
  }
  
  .blog-single-section .comments-area .comment-theme img {
    border-radius: 5px;
  }
  
  .blog-single-section .comments-area .comment-main-area {
    padding-left: 100px;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .comments-area .comment-main-area {
      padding-left: 0;
      margin-top: 25px;
    }
  }
  
  .blog-single-section .comments-area .comment-main-area p {
    margin-bottom: 20px;
  }
  
  .blog-single-section .comments-area .comments-meta h4 {
    font-size: 18px;
    font-size: 1.2rem;
    color: #233d62;
    font-weight: 600;
    margin: 0 0 1em;
  }
  
  .blog-single-section .comments-area .comments-meta h4 span {
    font-size: 13px;
    font-size: 0.86667rem;
    color: #687693;
    font-weight: normal;
    font-style: italic;
    text-transform: none;
    display: inline-block;
    padding-left: 5px;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .comments-area .comments-meta h4 span {
      padding-left: 0;
    }
  }
  
  .blog-single-section .comments-area .comment-reply-link {
    font-size: 15px;
    font-size: 1rem;
    color: #687693;
    font-weight: 600;
    display: inline-block;
  }
  
  .blog-single-section .comments-area .comment-reply-link .fi {
    display: inline-block;
    padding-right: 5px;
  }
  
  .blog-single-section .comments-area .comment-reply-link .fi:before {
    font-size: 15px;
    font-size: 1rem;
  }
  
  .blog-single-section .comments-area .comment-reply-link:hover {
    color: #fc6e36;
  }
  
  .blog-single-section .comment-respond {
    margin-top: 70px;
  }
  
  .blog-single-section .comment-respond .comment-reply-title {
    font-size: 22px;
    font-size: 1.46667rem;
    margin: 0 0 1.5em;
  }
  
  @media (max-width: 991px) {
    .blog-single-section .comment-respond .comment-reply-title {
      font-size: 20px;
      font-size: 1.33333rem;
    }
  }
  
  .blog-single-section .comment-respond form input,
  .blog-single-section .comment-respond form textarea {
    background-color: #fff;
    width: 100%;
    height: 50px;
    border: 1px solid #dfe2e8;
    padding: 6px 15px;
    margin-bottom: 15px;
    outline: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .blog-single-section .comment-respond form input:focus,
  .blog-single-section .comment-respond form textarea:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #fc6e36;
  }
  
  @media (max-width: 991px) {
    .blog-single-section .comment-respond form input,
    .blog-single-section .comment-respond form textarea {
      height: 40px;
    }
  }
  
  .blog-single-section .comment-respond form textarea {
    height: 220px;
    padding: 15px;
  }
  
  @media (max-width: 991px) {
    .blog-single-section .comment-respond form textarea {
      height: 150px;
    }
  }
  
  .blog-single-section .comment-respond .form-inputs {
    overflow: hidden;
  }
  
  .blog-single-section .comment-respond .form-inputs > input:nth-child(2) {
    width: 49%;
    float: left;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .comment-respond .form-inputs > input:nth-child(2) {
      width: 100%;
      float: none;
    }
  }
  
  .blog-single-section .comment-respond .form-inputs > input:nth-child(3) {
    width: 49%;
    float: right;
  }
  
  @media (max-width: 767px) {
    .blog-single-section .comment-respond .form-inputs > input:nth-child(3) {
      width: 100%;
      float: none;
    }
  }
  
  .blog-single-section .comment-respond .form-submit input {
    max-width: 180px;
    background-color: #fc6e36;
    color: #fff;
    margin-bottom: 0;
    border: 0;
    outline: 0;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 0;
  }
  
  .blog-single-section .comment-respond .form-submit input:hover {
    background-color: #fc5c1d;
  }
  
  @media screen and (min-width: 1200px) {
    .blog-single-left-sidebar-section .blog-sidebar {
      padding-right: 45px;
      padding-left: 0;
    }
  }