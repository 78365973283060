/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:     Seoul - SEO Digital Marketing Agency
Version:        1.0.0
Author:         itechtheme
URL:            https://themeforest.net/user/itechtheme
-------------------------------------------------------------------*/


@font-face {
  font-family: 'caros';
  src: url('../../fonts/caros.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'caros';
  src: url('../../fonts/caroslight.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'caros';
  src: url('../../fonts/carosmedium.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'caros';
  src: url('../../fonts/carosBold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
html {
  font-size: 15px;
}
.App{
  overflow: hidden;
}

body {
  font-family: "caros", sans-serif!important;
  background-color: #fff!important;
  font-size: 15px;
  font-size: 1rem;
  color: #687693;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}

p {
  color: #687693;
  line-height: 1.7em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700!important;
  color: #233d62;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  text-decoration: none!important;
}

img {
  max-width: 100%;
}

/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/
.page-wrapper {
  position: relative;
  overflow: hidden;
}

.wow {
  visibility: hidden;
}

.fi:before {
  margin: 0;
}

.section-padding {
  padding: 100px 0;
}

@media (max-width: 991px) {
  .section-padding {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .section-padding {
    padding: 80px 0;
  }
}


.theme-btn, .theme-btn-s3, .theme-btn-s4 {
  background-color: #fff;
  color: #fc6e36;
  font-size: 16px;
  font-size: 1.06667rem;
  font-weight: 600;
  padding: 17px 30px;
  display: inline-block;
  position: relative;
  border-radius: 60px;
}

.theme-btn:hover, .theme-btn-s3:hover, .theme-btn-s4:hover {
  background-color: #fc6e36;
  color: #fff;
}

@media (max-width: 991px) {
  .theme-btn, .theme-btn-s3, .theme-btn-s4 {
    font-size: 14px;
    font-size: 0.93333rem;
    padding: 14px 24px;
  }
}

@media (max-width: 767px) {
  .theme-btn, .theme-btn-s3, .theme-btn-s4 {
    font-size: 14px;
    font-size: 0.93333rem;
    padding: 14px 20px;
  }
}

.theme-btn-s2 {
  background-color: #fff;
  color: #fc6e36;
  font-size: 16px;
  font-size: 1.06667rem;
  font-weight: 600;
  padding: 17px 53px 17px 30px;
  display: inline-block;
  position: relative;
  border-radius: 3px;
}

.theme-btn-s2 i {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.theme-btn-s2:hover {
  background-color: #fc6e36;
  color: #fff;
}

@media (max-width: 991px) {
  .theme-btn-s2 {
    font-size: 14px;
    font-size: 0.93333rem;
    padding: 14px 40px 14px 20px;
  }
  .theme-btn-s2 i {
    right: 20px;
  }
}

.theme-btn-s3 {
  background-color: #eef0f3;
  color: #687693;
}

.theme-btn-s4 {
  background-color: #fc6e36;
  color: #fff;
}

.view-cart-btn {
  background: #fc6e36;
  width: 140px;
  padding: 10px;
  margin-top: 20px;
  color: white;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.view-cart-btn:hover {
  background-color: #fb4a04;
  color: #fff;
}


/* section-title */

.section-title, .section-title-s2, .section-title-s3, .section-title-s4, .section-title-s5, .section-title-s6, .section-title-s7, .section-title-s8 {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .section-title, .section-title-s2, .section-title-s3, .section-title-s4, .section-title-s5, .section-title-s6, .section-title-s7, .section-title-s8 {
    margin-bottom: 40px;
  }
}

.section-title > span, .section-title-s2 > span, .section-title-s3 > span, .section-title-s4 > span, .section-title-s5 > span, .section-title-s6 > span, .section-title-s7 > span, .section-title-s8 > span {
  background-color: rgba(252, 110, 54, 0.1);
  font-size: 14px;
  font-size: 0.93333rem;
  font-weight: 600;
  color: #fc6e36;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 50px;
}

@media (max-width: 767px) {
  .section-title > span, .section-title-s2 > span, .section-title-s3 > span, .section-title-s4 > span, .section-title-s5 > span, .section-title-s6 > span, .section-title-s7 > span, .section-title-s8 > span {
    font-size: 13px;
    font-size: 0.86667rem;
  }
}

.section-title h2, .section-title-s2 h2, .section-title-s3 h2, .section-title-s4 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2, .section-title-s8 h2 {
  font-size: 36px;
  font-size: 2.4rem;
  margin: 0.3em 0 0.54em;
  position: relative;
}

@media (max-width: 991px) {
  .section-title h2, .section-title-s2 h2, .section-title-s3 h2, .section-title-s4 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2, .section-title-s8 h2 {
    font-size: 30px;
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .section-title h2, .section-title-s2 h2, .section-title-s3 h2, .section-title-s4 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2, .section-title-s8 h2 {
    font-size: 24px;
    font-size: 1.6rem;
  }
}

.section-title-s2, .section-title-s3 {
  text-align: center;
}

.section-title-s3 > span {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.section-title-s3 h2,
.section-title-s3 p {
  color: #fff;
}

.section-title-s4 {
  margin-bottom: 30px;
}

.section-title-s5, .section-title-s6, .section-title-s7, .section-title-s8 {
  margin-bottom: 30px;
}

.section-title-s5 > span, .section-title-s6 > span, .section-title-s7 > span, .section-title-s8 > span {
  background-color: transparent;
  padding: 0;
}

.section-title-s6, .section-title-s7, .section-title-s8 {
  text-align: center;
}

.section-title-s7 h2,
.section-title-s7 p {
  color: #fff;
}

.section-title-s8 {
  text-align: left;
}